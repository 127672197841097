/*
    handle storing cookies
*/
function store_cookie (cookie_name, user_choice) {
    // store current root domain for use in cookies
    const domain = encodeURI(window.location.host);
    // retrieve date one year from now
    Date.prototype.addYears = function (years) {
        const now = new Date();
        return new Date(now.setFullYear(now.getFullYear() + years));
    };
    // store date one year from now as the cookie expiration date
    const expiration_date = new Date().addYears(1);
    // store generic cookie arguments
    const cookie_arguments = `expires=${expiration_date}; path=/; SameSite=None; Secure;`;
    // construct all cookie arguments
    const constructed_cookie = `${cookie_name}=${user_choice}; domain=${domain}; ${cookie_arguments}`;
    // store this cookie
    document.cookie = constructed_cookie;
}
/*
    retrieve cookie
*/
function retrieve_cookie (cookie_name) {
    let match = document.cookie.match(RegExp("(?:^|;\\s*)" + cookie_name + "=([^;]*)"));
    if (match !== null) {
        match = match[1];
    }
    return match;
}
/*
    handle visibility of cookie notice messages
*/
function handle_notice_visibility () {
    // retrieve this notice element
    const notice_element = document.getElementById("cookie_notice");
    // if this notice element is present in markup
    if (notice_element) {
        // apply or remove class which displays this notice
        notice_element.classList.toggle("cookie_notice--is_visible");
        // apply or remove a class to the body element to avoid the user scrolling the content
        const body = document.getElementById("application_container");
        body.classList.toggle("application_container--modal_is_visible");
    }
}
/*
    handle user's decision on storing analytics cookies, approve Google Tag Manager if user accepts

    Args:
        analytic_preferences: String, possible values: "granted", "denied"
        google_tag: String, tag as provided by Google Analytics
*/
function send_permissions_to_google (analytics_preferences, google_tag) {
    /* global dataLayer */
    const user_preferences = {
        "event": "cookie_preferences",
        "eventData": {
            "analytics_preferences": analytics_preferences,
        },
    };
    window.dataLayer = window.dataLayer || [];
    function gtag () {
        dataLayer.push(arguments);
    }
    gtag(user_preferences);
    gtag("js", new Date());
    gtag("config", google_tag, {cookie_flags: "SameSite=None;Secure"});
}
/*
    handle storing cookies and allowing Google Analytics access
    when user interacts with the cookie notice
*/
export default function () {
    // check for presence of `google_tag` in the HTML
    /* global google_tag enable_cookie_notice */
    if (typeof google_tag === "undefined") {
        // if google_tag is not present don't bother initialising, no tracking will happen.
        console.log("Google Tag not provided, no tracking is set up.");
        return;
    }
    // enable_cookie_notice should be set in the HTML
    if (enable_cookie_notice !== true) {
        send_permissions_to_google("granted", google_tag);
        return;
    }
    // store notice cookie key name. will have a value of 'dismissed' if user has dismissed the cookie notice
    const notice_cookie_name = "cookie_notice_status";
    // store Google Analytics cookie key name
    const google_analytics_cookie_name = "analytics_preferences";
    // retrieve existing cookie
    const existing_notice_cookie = retrieve_cookie(notice_cookie_name);
    // check whether cookie notice element has previously been dismissed in any way
    if (existing_notice_cookie === null) {
        /*
            cookie notice has not yet been seen by the user, so present the user with all relevant options
        */
        // make the cookie notice visible
        handle_notice_visibility();
        /*
            handle the cookie notice 'accept all cookies' button element
        */
        // store the cookie notice 'accept all cookies' button element
        const accept_all_button = document.getElementById("cookie_accept_all");
        if (accept_all_button) {
            // when user interacts with accept all cookies button element
            accept_all_button.onclick = () => {
                // hide cookie notice
                handle_notice_visibility();
                // if Google Analytics is enabled for this environment, store user acceptance
                store_cookie(google_analytics_cookie_name, "granted");
                // if present, send user acceptance to Google
                const analytics_preferences = "granted";
                send_permissions_to_google(analytics_preferences, google_tag);
                // store cookie to track that the user has dismissed the cookie notice
                store_cookie(notice_cookie_name, "dismissed");
            };
        }
        /*
            handle the cookie notice 'save settings' button element
        */
        // store the cookie notice 'save settings' button element
        const save_dismiss_button = document.getElementById("cookie_save_and_dismiss");
        if (save_dismiss_button) {
            // when user interacts with save & dismiss button
            save_dismiss_button.onclick = () => {
                // hide cookie notice
                handle_notice_visibility();
                let analytics_preferences;
                // store the relevant checkbox input element
                const google_checkbox = document.getElementById("google_analytics_toggle");
                if (google_checkbox.checked) {
                    // store cookie accepting analytics
                    store_cookie(google_analytics_cookie_name, "granted");
                    analytics_preferences = "granted";
                } else {
                    // store cookie denying analytics
                    store_cookie(google_analytics_cookie_name, "denied");
                    analytics_preferences = "denied";
                }
                send_permissions_to_google(analytics_preferences, google_tag);
                // store cookie to track that the user has dismissed the cookie notice
                store_cookie(notice_cookie_name, "dismissed");
            };
        }
    } else {
        /*
            cookie notice has previously been dismissed,
            so respect all specific analytics choices which were stored
        */
        const analytics_preferences = retrieve_cookie(google_analytics_cookie_name);
        send_permissions_to_google(analytics_preferences, google_tag);
    }
}
