/*
    initialise the Sentry SDK
*/
export default function () {
    /* global Sentry sentry_dsn sentry_environment sentry_release */
    // check for presence of `sentry_dsn` in the HTML
    if (typeof sentry_dsn === "undefined") {
        return;
    }
    // check if Sentry is defined
    if (typeof Sentry === "undefined") {
        return;
    }
    // initialise Sentry
    Sentry.init({
        dsn: sentry_dsn,
        release: sentry_release,
        integrations: [new Sentry.BrowserTracing()],
        // adjust this value in production, or using tracesSampler for finer control
        tracesSampleRate: 1.0,
        environment: sentry_environment,
    });
    // listen for 404 errors
    document.body.addEventListener(
        "error",
        event => {
            if (!event.target) return;
            if (event.target.tagName === "IMG") {
                Sentry.captureMessage(
                    `Failed to load image: ${event.target.src}`,
                    "warning"
                );
            } else if (event.target.tagName === "LINK") {
                Sentry.captureMessage(
                    `Failed to load css: ${event.target.href}`,
                    "warning"
                );
            }
        },
        true // useCapture - necessary for resource loading errors
    );
}
