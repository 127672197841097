/*
    add containing element to tables, to allow CSS to target them
*/
export default function () {
    // retrieve all tables
    const tables = document.querySelectorAll("table");
    // return if no tables are found
    if (tables === null) {
        return;
    }
    // loop over all tables
    tables.forEach((table) => {
        // retrieve this table's parent element
        const parent = table.parentNode;
        // create a div element
        const table_wrap = document.createElement("div");
        // give that div element the relevant class name
        table_wrap.classList.add("table_wrap");
        // insert the div element into the DOM, before the table
        parent.insertBefore(table_wrap, table);
        // move the table into the div element
        table_wrap.appendChild(table);
    });
}
