import { animate_height, set_existing_height } from "./utilities/animate_visibility";

/*
    Animate the height of frequently asked questions details elements when user interacts with the
    relevant summary element.
*/
export default function () {
    const faqs = document.querySelectorAll(".frequently_asked_questions__question_and_answer");
    if (!faqs.length) return;
    faqs.forEach(faq => {
        const question = faq.querySelector(".frequently_asked_questions__question");
        const answer = faq.querySelector(".frequently_asked_questions__answer");
        if (!question || !answer) return;
        const classname_question_visibility = "frequently_asked_questions__question--answer_is_visible";
        const classname_answer_visibility = "frequently_asked_questions__answer--is_visible";
        question.addEventListener(
            "click",
            animate_height(question, classname_question_visibility, answer, classname_answer_visibility)
        );
    });
    /*
        Recalculate height once viewport size changes.
    */
    let viewport_resize_timer = null;
    window.onresize = () => {
        if (viewport_resize_timer !== null) {
            window.clearTimeout(viewport_resize_timer);
        }
        viewport_resize_timer = window.setTimeout(() => {
            faqs.forEach(faq => {
                const answer = faq.querySelector(".frequently_asked_questions__answer");
                if (!answer) return;
                const classname_answer_visibility = "frequently_asked_questions__answer--is_visible";
                if (answer.classList.contains(classname_answer_visibility)) {
                    answer.style.height = "";
                    window.setTimeout(() => {
                        set_existing_height(answer, classname_answer_visibility);
                    }, 100);
                }
            });
        }, 200);
    };
}
