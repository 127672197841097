/*
    Watch for process explanation list to be in the viewport, and apply relevant class when it is.
*/
export default function () {
    const selector_process_explanation_list = "process_explanation_list";
    const element_target = document.getElementById(selector_process_explanation_list);
    if (!element_target) return;
    const observer = new IntersectionObserver(
        entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    element_target.classList.add("platform__process_explanation__list--is_visible");
                    observer.unobserve(entry.target);
                }
            });
        },
        { threshold: 0.1 }
    );
    observer.observe(element_target);
}
