/*
    Display file information when user interacts with a file input
*/
export default function () {
    const file_inputs = document.querySelectorAll("input[type=\"file\"]");
    if (file_inputs === null) return;
    file_inputs.forEach(file_input => {
        const selectors = {
            button_clear: ".form_component__selected_file_information__clear_button",
            file_information: ".form_component__selected_file_information__text",
            file_size_warning: "#file_upload_size_error",
        };
        const parent = file_input.parentElement;
        const button_clear = parent.querySelector(selectors.button_clear);
        const file_information = parent.querySelector(selectors.file_information);
        const file_size_warning = parent.querySelector(selectors.file_size_warning);
        if (!file_information || !file_size_warning) return;
        file_input.addEventListener(
            "change",
            (event) => {
                file_size_warning.style.display = "none";
                const [file] = event.target.files;
                const { name: file_name, size } = file;
                const file_size_in_kb = Math.round((size / 1024) * 1e2) / 1e2;
                const file_name_and_size = `${file_name} — ${file_size_in_kb}kB`;
                file_information.textContent = file_name_and_size;
                if (file_size_in_kb > 10240) { // 10MB
                    file_input.value = "";
                    file_size_warning.style.display = "block";
                } else {
                    if (!button_clear) return;
                    button_clear.hidden = false;
                }
            }
        );
        if (!button_clear) return;
        button_clear.addEventListener(
            "click",
            () => {
                file_information.textContent = "";
                file_input.value = "";
                button_clear.hidden = true;
            }
        );
    });
}
