/*
    When a checkbox label contains a link, we make the clickable area of the hidden checkbox
    smaller, to allow the label to have a higher z-index than that of the checkbox. This introduces
    an inconsistency, as clicking the non-anchor label text no longer checks the checkbox. This
    function reintroduces that functionality to checkboxes with labels that contain anchors.
*/
export default function () {
    const selectors = {
        checkbox: "form_component__checkbox",
        label_with_anchor: "form_component__simulated_checkbox__label--has_anchor",
    };
    const elements = {
        labels_with_anchor: document.querySelectorAll(`.${selectors.label_with_anchor}`),
    };
    if (elements.labels_with_anchor.length === 0) return;
    elements.labels_with_anchor.forEach(label_with_anchor => {
        const checkbox = label_with_anchor.parentElement?.querySelector(`.${selectors.checkbox}`);
        if (!checkbox) return;
        label_with_anchor.addEventListener(
            "click",
            (event) => {
                // return if the click was on the label's anchor element
                if (event.target.tagName === "A") return;
                checkbox.checked = !checkbox.checked;
            }
        );
    });
}
