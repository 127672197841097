/*
    Function to animate the height of an element opening and closing, and display a relevant icon
    animation on the element that triggered the opening/closing.
*/
export function animate_height (button, classname_button_visibility, target, classname_target_visibility, target_animation_complete_class = null) {
    // use curried function to allow passing variables to this function within `addEventListener`
    return () => {
        if (target.classList.contains(classname_target_visibility)) {
            target.style.overflow = "hidden";
            target.style.removeProperty("height");
            if (target_animation_complete_class) {
                target.classList.remove(target_animation_complete_class);
            }
        } else {
            target.style.overflow = "hidden";
            const full_height = target.scrollHeight;
            target.style.height = full_height + "px";
            if (target_animation_complete_class) {
                setTimeout(() => {
                    target.classList.add(target_animation_complete_class);
                    target.style.overflow = "visible";
                }, 200);
            }
        }
        target.classList.toggle(classname_target_visibility);
        if (classname_button_visibility) {
            button.classList.toggle(classname_button_visibility);
        }
    };
}
/*
    Function to set an explicit height on an element which should be animated out of visibility.
*/
export function set_existing_height (element, element_visibility_class, element_animation_complete_class) {
    if (
        element.classList.contains(element_visibility_class) &&
        element_is_visible(element)
    ) {
        const full_height = element.scrollHeight;
        element.style.height = full_height + "px";
        element.classList.add(element_animation_complete_class);
    }
    function element_is_visible (element) {
        while (element) {
            if (window.getComputedStyle(element).display === "none") {
                return false;
            }
            element = element.parentElement;
        }
        return true;
    }
}
