/*
    handle visibility of global navigation
    when user interacts with the relevant buttons
*/
function toggle_navigation_visibility () {
    const body = document.getElementById("application_container");
    const navigation = document.getElementById("global_header_navigation");
    if (navigation === null) {
        return;
    }
    navigation.classList.toggle("global_header__navigation--is_visible");
    if (body === null) {
        return;
    }
    body.classList.toggle("application_container--modal_is_visible");
}
export default function () {
    const display_button = document.getElementById("global_navigation_display_button");
    const hide_button    = document.getElementById("global_navigation_hide_button");
    if (display_button === null || hide_button === null) {
        return;
    }
    display_button.addEventListener("click", toggle_navigation_visibility, false);
    hide_button.addEventListener("click", toggle_navigation_visibility, false);
}
