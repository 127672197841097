"use strict";
// import functions
import animate_process_explanation_visibility from "./modules/animate_process_explanation_visibility";
import assemble_email_addresses from "./modules/assemble_email_addresses";
import focus_accessibility from "./modules/focus_accessibility";
import handle_checkbox_label_anchors from "./modules/handle_checkbox_label_anchors";
import handle_cookie_notice from "./modules/handle_cookie_notice";
import handle_file_uploads from "./modules/handle_file_uploads";
import visibility_global_navigation from "./modules/visibility_global_navigation";
import initialise_sentry from "./modules/initialise_sentry";
import type_ratio_interpolation from "./modules/type_ratio_interpolation";
import visibility_frequently_asked_questions from "./modules/visibility_frequently_asked_questions";
import wrap_html_tables from "./modules/wrap_html_tables";
/*
    Interpolate between type ratio minimum and maximum values, and store the result in a CSS
    custom property.
*/
type_ratio_interpolation();
/*
    Allow enchanced focus detection (depends on a11y.js).
*/
focus_accessibility();
/*
    Handle storing cookies and allowing Google Analytics access when user interacts with the cookie
    notice.
*/
handle_cookie_notice();
/*
    Assemble e-mail address after page load, so that bots can't harvest details.
*/
assemble_email_addresses();
/*
    Handle visibility of global navigation when user interacts with the relevant buttons.
*/
visibility_global_navigation();
/*
    Display file information when user interacts with a file input.
*/
handle_file_uploads();
/*
    Add containing element to tables, to allow CSS to target them.
*/
wrap_html_tables();
/*
    Animate the height of frequently asked questions details elements
    when user interacts with the relevant summary element.
*/
visibility_frequently_asked_questions();
/*
    Initialise the Sentry SDK
*/
initialise_sentry();
/*
    Handle allowing a click on the non-anchor text on checkbox labels which have anchors to change
    the checkbox's checked state.
*/
handle_checkbox_label_anchors();
/*
    Watch for process explanation list to be in the viewport, and apply relevant class when it is.
*/
animate_process_explanation_visibility();
