/*
    assemble e-mail address after page load, so that bots can't harvest details
*/
export default function () {
    // ignore linting for these variables, which are present in the template
    /* global email_address_start email_address_end */
    // check for presence of email address parts
    if (!email_address_start || !email_address_end) {
        return;
    }
    // assemble full email address
    const email_address_full = `${email_address_start}@${email_address_end}`;
    // retrieve all email links in document
    const email_links = document.querySelectorAll(".email_link");
    if (email_links.length > 0) {
        // loop over all email links in document
        email_links.forEach(email_link => {
            // set mailto href
            email_link.setAttribute("href", `mailto:${email_address_full}`);

        });
    }
    // retrieve all text elements displaying email address in document
    const email_text_nodes = document.querySelectorAll(".email_text");
    if (email_text_nodes.length > 0) {
        // loop over all text elements displaying email address in document
        email_text_nodes.forEach(email_text_node => {
            // set element inner text
            email_text_node.innerText = email_address_full;
        });
    }
}
